/**
 * using ../public/fonts instead of /fonts lets webpack do its thing and
 * load with hashed urls
 */

 @font-face {
  font-family: 'Sport1-Regular';
  src: url("../public/fonts/Sport1-VariableFont.woff2") format("woff2");
  font-weight: 400;
  font-stretch: 100%; 
}

@font-face {
  font-family: 'Sport1-RegularItalic';
  src: url("../public/fonts/Sport1-VariableFont.woff2") format("woff2");
  font-weight: 400;
  font-stretch: 100%;
}

@font-face {
  font-family: 'Sport1-Bold';
  src: 
       url("../public/fonts/Sport1-VariableFont.woff2") format("woff2");
  font-weight: 700;
  font-stretch: 100%;
}

@font-face {
  font-family: 'Sport1-BoldItalic';
  src: 
       url("../public/fonts/Sport1-VariableFont.woff2") format("woff2");
  font-weight: 700;
  font-stretch: 100%;
}

@font-face {
  font-family: 'Sport1-CondensedRegularItalic';
  src: url("../public/fonts/Sport1-VariableFont.woff2") format("woff2");
  font-weight: 400;
  font-stretch: 50%; 
}

@font-face {
  font-family: 'Sport1-CondensedBlackItalic';
  src: url("../public/fonts/Sport1-VariableFont.woff2") format("woff2");
  font-weight: 900;
  font-stretch: 50%;
}

@font-face {
  font-family: 'sport1-universal-components';
  src: url('../public/fonts/fontello/sport1-universal-components.ttf');
}

@font-face {
  font-family: 'sport1-styleguide';
  src: url('../public/fonts/fontello/sport1-styleguide.woff2');
}

/* Fallbacks */
@font-face {
  font-family: "Sport1-CondensedBlackItalic-Fallback";
  src: local(Arial);
  size-adjust: 78%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-CondensedRegularItalic-Fallback";
  src: local(Arial);
  size-adjust: 78%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-RegularItalic-Fallback";
  src: local(Arial);
  size-adjust: 78%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-Regular-Fallback";
  src: local(Arial);
  size-adjust: 93.6%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-Bold-Fallback";
  src: local(Arial);
  size-adjust: 98.4%;
  descent-override: 15%;
}